<template>
  <div class="amendments_box">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: '/cases/detail',
        name: $t('casesDetail.casesChildren.common.blxq')
      }, {
        url: backUrl,
        name: backTxt
      }]"
      :currentTxt="$t('casesDetail.casesChildren.amendments.tjxgyj')"
    />
    <div class="amend_con">
      <div class="amend_con_head">
        <div class="amend__h_left">{{$t('casesDetail.casesChildren.amendments.myfa')}}</div>
        <div class="main_big_btn main_theme_color_btn" @click="viewHisRecord">
          <span class="h_r_icon"></span>
          {{$t('casesDetail.casesChildren.amendments.ckls')}}
        </div>
      </div>
      <div class="amend__con">
        <h5 class="amend__con_h5">{{secondName}}</h5>
        <div class="amend_txt">
          <div v-if="textPlan.length > 0">
            <p v-for="(item, index) in textPlan" :key="`textPlan_${index}`">{{item}}</p>
          </div>
          <div class="summary_box" v-else>
            <div class="summary_single" v-for="(item, index) in cureSummary.noteList" :key="`cz_${index}`">
              <div class="s_s_left" v-if="lang === 'zh_CN'">第{{item.orderNo}}步：</div>
              <div class="s_s_left" v-else>Step {{item.orderNo}}：</div>
              <div class="s_s_right">
                <p v-for="(it, index) in item.note" :key="`cz_n_${index}`">{{it}}</p>
              </div>
            </div>
            <div class="summary_single" v-if="cureSummary.otherNoteObj.note">
              <div class="s_s_left">{{$t('common.message.qtlcczsm')}}：</div>
              <div class="s_s_right">
                <p v-for="(it, index) in cureSummary.otherNoteObj.note" :key="`cz_qt_${index}`">{{it}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="amend__con_iup">
          <textarea :placeholder="$t('casesDetail.casesChildren.amendments.srxgyj')" v-model="operateRemark"></textarea>
        </div>
        <p class="amend__con_p">{{$t('casesDetail.casesChildren.amendments.xgyjlr')}}</p>
      </div>
      <div class="amend_foo w100 flex-x-c">
        <div class="main_theme_color_btn main_big_btn"
          @click="operatorLogDoctor">{{$t('casesDetail.casesChildren.amendments.tjxgyj')}}</div>
      </div>
    </div>
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import {confirmCurePlan, confirmTextPlan} from 'common/api/cases';
  import {notifyMsg, splitText} from 'common/js/util';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        isLoading: false,
        secondName: '',
        operateRemark: '',
        type: '',
        backUrl: '',
        backTxt: '',
        textPlan: [],
        cureSummary: {
          noteList: [],
          otherNoteObj: {}
        },
        lang: localStorage.getItem('user_lang') || 'zh_CN'
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.amendments.tjxgyj');
      const {type = ''} = this.$route.query;
      const planMsg = JSON.parse(sessionStorage.getItem('planMsg') || '{}');
      this.secondName = planMsg.secondName || planMsg.planSecondName;
      if(type !== 'textPlan') {
        this.secondName = this.$t('casesDetail.casesDetail.lcczsm');
        const cureSummary = JSON.parse(sessionStorage.getItem('cureSummary') || '{}');
        const {noteList = [], otherNoteObj = {}} = cureSummary;
        this.cureSummary = {
          noteList: noteList.map(item => ({
            ...item,
            note: splitText(item.note)
          })),
          otherNoteObj: {
            ...otherNoteObj,
            note: splitText(otherNoteObj.note)
          }
        };
      }else {
        this.textPlan = splitText(planMsg.textPlan);
      }
      this.type = type;
      this.backUrl = type === 'textPlan' ? '/cases/reviewTextPlan' : '/cases/reviewPlan';
      this.backTxt = type === 'textPlan' ? this.$t('casesDetail.casesChildren.amendments.qdwz') : this.$t('casesDetail.casesChildren.amendments.fhzl');
      sessionStorage.removeItem('planMsg');
    },
    methods: {
      operatorLogDoctor() {
        const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId');
        const casesId = localStorage.getItem('casesId') || sessionStorage.getItem('casesId');
        sessionStorage.setItem('curePlanId', curePlanId);
        sessionStorage.setItem('casesId', casesId);
        localStorage.removeItem('curePlanId');
        localStorage.removeItem('casesId');
        if(curePlanId) {
          if(!this.operateRemark) {
            return notifyMsg(this, 'warning', this.$t('casesDetail.casesChildren.amendments.qxtx'));
          }
          this.isLoading = true;
          const confirmApi = this.type === 'textPlan' ? confirmTextPlan : confirmCurePlan;
          confirmApi({
            id: curePlanId,
            approveResult: '0',
            approveNote: this.operateRemark
          }).then(() => {
            this.isLoading = false;
            notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.amendments.czcg')).then(() => {
              this.goPage('/cases/detail');
            });
          }).catch(() => {
            this.isLoading = false;
          });
        }else {
          notifyMsg(this, 'error', this.$t('casesDetail.casesChildren.amendments.czsb'));
        }
      },
      viewHisRecord() {
        const url = this.type === 'textPlan' ? '/cases/hisTextPlan' : '/cases/hisEditOpinion';
        this.goPage(url);
      },
      goPage(url) {
        this.$router.push(url);
      },
      goBack() {
        window.history.go(-1);
      }
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
    },
    components: {
      BackStep,
      FullLoading
    }
  }
</script>

<style scoped lang="scss">
  .amendments_box{
    width: 13.44rem;
    margin: 0 auto;
    font-size: 0.16rem;
    color: $main_theme_color_333;
    .amend_con{
      background-color: #fff;
      border-radius: 0.1rem;
      padding: 0.22rem 0.8rem 0.25rem 0.8rem;
      .amend_con_head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.6rem;
        font-size: 0.24rem;
      }
      .amend__con{
        margin-bottom: 0.35rem;
        .amend__con_h5{
          font-size: 0.18rem;
          line-height:0.34rem;
          font-weight: 600;
          color: $main_theme_color_333;
          margin-bottom: 0.25rem;
        }
        .amend_txt{
          color: #999999;
          font-size: 0.16rem;
          line-height: 0.27rem;
          margin-bottom: 0.4rem;
          padding-left: 0.2rem;
        }
        .s_s_left{
          color: $main_theme_color_333;
        }
        .amend__con_iup{
          border: 0.01rem solid #E7E7E7;
          padding: 0.1rem 0.2rem;
          border-radius: 0.06rem;
          height: 2rem;
          box-sizing: border-box;
          color: $main_theme_color_333;
          margin-bottom: 0.28rem;
          line-height: 0.26rem;
          textarea{
            padding: 0.09rem;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
          }
        }
        .amend__con_p{
          color: #999999;
          padding-left: 0.34rem;
        }
      }
    }
    .amend_foo{
      text-align: center;
    }
  }
</style>
